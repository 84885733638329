import request from '@/utils/request'
import requestpub from '@/utils/requestpub'
// import requestb from '@/utils/requestb'

// 前端使用 =======================================================
// 注册
export const reg = data => {
  return request({
    method: 'POST',
    url: '/user/reg',
    data: data
  })
}
export const reg2 = data => {
  return request({
    method: 'POST',
    url: '/user/reg2',
    data: data
  })
}
export const increg = data => {
  return request({
    method: 'POST',
    url: '/user/increg',
    data: data
  })
}
// 登陆
export const login = data => {
  return request({
    method: 'POST',
    url: '/user/login',
    data: data
  })
}
// 发送注册验证码
export const codesend = (data) => {
  return request({
    method: 'POST',
    url: '/user/codesend?tp=pc',
    data: data
  })
}

export const pck = (data) => {
  return request({
    method: 'POST',
    url: '/user/pcksend',
    data: data
  })
}
// 栏目获取
export const category = (t, id) => {
  const urls = '/category/categorys?pagesize=500&t=' + t + '&rt=' + id
  return request({
    method: 'GET',
    url: urls
  })
}

// 扫码登陆获取登陆数据
export const getsaoma = (id) => {
  return request({
    method: 'GET',
    url: '/user/saoma?logint=1&loginid=' + id
  })
}
// 简历管理中心 ==============
// 用户管理使用 =====================================================

// 用户信息-----------------------------------------------------------------
// 获取登陆用户
export const getuserprofile = () => {
  return request({
    method: 'GET',
    url: '/user/userme?tp=pc'
  })
}
// 获取简历信息
export const rgetresume = () => {
  return request({
    method: 'GET',
    url: '/resume/resume'
  })
}
export const rresumeput = (data) => {
  return request({
    method: 'PUT',
    url: '/resume/resume',
    data: data
  })
}
// 申请职位
export const applysq = (jobid, companyid) => {
  return request({
    method: 'PUT',
    url: '/apply/applysq?companyid=' + companyid + '&jobid=' + jobid
  })
}

// ---企业、个人通用---------------------------------------------

// 个人应聘记录 / 企业收到的简历 ==============
export const rapplys = (page, at) => {
  return request({
    method: 'GET',
    url: '/apply/applys?pagesize=12&page=' + page + '&at=' + at
  })
}
// 应聘记录、收到的简历操作
export const applyedit = (ids, t) => {
  return request({
    method: 'PUT',
    url: '/apply/applyedit?t=' + t + '&id=' + ids
  })
}
// 企业面试邀请 / 个人收到的邀请==============
export const rinvites = (page) => {
  return request({
    method: 'GET',
    url: '/invite/invites?pagesize=12&page=' + page
  })
}

// 面试邀请、收到的面试通知操作
export const inviteedit = (ids, t) => {
  return request({
    method: 'PUT',
    url: '/invite/inviteedit?t=' + t + '&id=' + ids
  })
}

// 企业简历收藏管理 /个人职位收藏 ==============
export const rcollections = (page) => {
  return request({
    method: 'GET',
    url: '/collection/collections?pagesize=12&page=' + page
  })
}

// 找回密码
export const passwordget = (pinfo) => {
  return request({
    method: 'POST',
    url: '/user/passwordget?p=' + pinfo
  })
}

// 企业/个人查看管理 ==============
export const rreadings = (page, t) => {
  return request({
    method: 'GET',
    url: '/reading/readings?t=' + t + '&pagesize=12&page=' + page
  })
}

// 收藏操作
export const collectionedit = (ids, t) => {
  return request({
    method: 'PUT',
    url: '/collection/collectionedit?t=' + t + '&id=' + ids
  })
}
// 收藏删除
export const collectiondelete = (ids) => {
  return request({
    method: 'DELETE',
    url: '/collection/collection?ids=' + ids
  })
}
// 添加收藏
export const collectyonsc = (cid) => {
  return request({
    method: 'PUT',
    url: '/collection/collectionsc?cid=' + cid
  })
}

// 管理中心数量统计
export const resumecount = () => {
  return request({
    method: 'GET',
    url: '/resume/resumecount'
  })
}
// ------------------------------------------------
// 企业信息 ==============
export const companys = () => {
  return request({
    method: 'GET',
    url: '/company/company'
  })
}
export const companyscount = () => {
  return request({
    method: 'GET',
    url: '/company/companycount'
  })
}
export const companysput = (data) => {
  return request({
    method: 'PUT',
    url: '/company/company',
    data: data
  })
}
export const departments = (page) => {
  return request({
    method: 'GET',
    url: '/department/departments?pagesize=100&page=1'
  })
}
// 共用调用
export const departmentss = (cid, dname) => {
  return request({
    method: 'GET',
    url: '/department/departmentss?pagesize=100&page=1&dname=' + dname + '&cid=' + cid
  })
}
export const departmentsput = (data) => {
  return request({
    method: 'PUT',
    url: '/department/department',
    data: data
  })
}
export const departmentsdel = (id) => {
  return request({
    method: 'DELETE',
    url: '/department/department?id=' + id
  })
}
export const companypic = (t, fn) => {
  return request({
    method: 'POST',
    url: '/company/pic?t=' + t + '&fn=' + fn
  })
}
export const jobsput = (data) => {
  return request({
    method: 'PUT',
    url: '/job/job',
    data: data
  })
}
export const jobsdel = (id) => {
  return request({
    method: 'DELETE',
    url: '/job/job?id=' + id
  })
}
// 帐户操作
export const paysget = () => {
  return request({
    method: 'GET',
    url: '/pay/pays'
  })
}
export const payssum = () => {
  return request({
    method: 'GET',
    url: '/pay/paysum'
  })
}
export const paysset = (key) => {
  return request({
    method: 'PUT',
    url: '/pay/payset?paykey=' + key
  })
}
// 开通服务
export const companyserve = (tcid) => {
  return request({
    method: 'PUT',
    url: '/company/companyserve?tcid=' + tcid
  })
}

// 刷新操作
export const jobedit = (ids, t) => {
  return request({
    method: 'PUT',
    url: '/job/jobedit?t=' + t + '&id=' + ids
  })
}

// 邀请面试操作
export const inviteyq = (jobid, resumeid) => {
  return request({
    method: 'PUT',
    url: '/invite/inviteyq?resumeid=' + resumeid + '&jobid=' + jobid
  })
}

// 推广管理
export const promotionget = (t) => {
  return request({
    method: 'GET',
    url: '/promotion/promotions?t=' + t + '&pagesize=50&page=1'
  })
}
export const promotionput = (t) => {
  return request({
    method: 'PUT',
    url: '/promotion/promotion?t=' + t
  })
}

// --企业管理中心-职位管理----------------------------------------------
export const cjobmanage = (t, page, keys, pagesize, hide) => {
  return request({
    method: 'GET',
    url: '/job/jobs?t=' + t + '&pagesize=' + pagesize + '&page=' + page + '&key=' + keys + '&hide=' + hide
  })
}

export const cjobitemmanage = (id) => {
  return request({
    method: 'GET',
    url: '/job/job?id=' + id
  })
}

// 密码修改
export const passwordset = (password) => {
  return request({
    method: 'POST',
    url: '/user/password',
    data: password
  })
}

// 简历备注操作
export const resumelog = (rid, rt, data) => {
  if (rt === 0) {
    return request({
      method: 'PUT',
      url: '/company/resumelog?rid=' + rid
    })
  } else {
    return request({
      method: 'PUT',
      url: '/company/resumelog?edit=1&rid=' + rid,
      data: data
    })
  }
}

// 公共人才列表 ==============
export const resumes = (page) => {
  return request({
    method: 'GET',
    url: '/resume/resumes?pagesize=30&page=' + page
  })
}

// 简历搜索
export const resumesearch = (page, keys) => {
  let tj = ''
  if (keys === 'tj') {
    tj = '&tj=tj'
  }
  return request({
    method: 'GET',
    url: '/resume/search?pagesize=30&page=' + page + '&keys=' + keys + tj
  })
}
// 简历搜索
export const resumesearchs = (page, pagesize, keys, ctinfo) => {
  let tj = ''
  if (keys === 'tj') {
    tj = '&tj=tj'
  }
  // 前台搜索
  return request({
    method: 'GET',
    url: '/resume/search?pagesize=' + pagesize + '&page=' + page + '&keys=' + keys + tj + '&ctinfo=' + ctinfo
  })
}

export const logoff = () => {
  return request({
    method: 'GET',
    url: '/user/userrm'
  })
}

// 公共职位列表 ==============
export const jobs = (t, page, keys) => {
  return request({
    method: 'GET',
    url: '/job/jobss?t=' + t + '&pagesize=30&page=' + page
  })
}

// 热点招聘 ==============
export const hotjobs = () => {
  return request({
    method: 'GET',
    url: '/job/hot?&pagesize=30&page=1'
  })
}
// 搜索
export const jobsearch = (page, keys) => {
  let tj = ''
  if (keys === 'tj') {
    tj = '&tj=tj'
  }
  return request({
    method: 'GET',
    url: '/job/search?pagesize=30&page=' + page + '&keys=' + keys + tj
  })
}
// 前台岗位搜索
export const jobsearchs = (page, pagesize, keys, jyinfo, xlinfo, cinfo, czinfo) => {
  let tj = ''
  if (keys === 'tj') {
    tj = '&tj=tj'
  }
  let url = '/job/search?pagesize=' + pagesize + '&page=' + page
  if (tj.length > 0) {
    url += '&tj=' + tj
  }
  if (keys.length > 0) {
    url += '&keys=' + keys
  }
  if (jyinfo.length > 0) {
    url += '&jyinfo=' + jyinfo
  }
  if (xlinfo.length > 0) {
    url += '&xlinfo=' + xlinfo
  }
  if (cinfo.length > 0) {
    url += '&cinfo=' + cinfo
  }
  if (czinfo.length > 0) {
    url += '&czinfo=' + czinfo
  }
  return request({
    method: 'GET',
    url: url
  })
}

export const jobsb = (t, page, pagesize, keys, order) => {
  return request({
    method: 'GET',
    url: '/job/jobss?t=' + t + '&pagesize=' + pagesize + '&page=' + page + '&key=' + keys + '&order=' + order
  })
}
export const jobsc = (t, page, pagesize, keys, order, cid) => {
  return request({
    method: 'GET',
    url: '/job/jobss?t=' + t + '&pagesize=' + pagesize + '&page=' + page + '&key=' + keys + '&cid=' + cid
  })
}
export const companypics = (id) => {
  return request({
    method: 'GET',
    url: '/company/pics?id=' + id
  })
}

// 公共获取简历信息
export const getresumeitem = (id, edit) => {
  return request({
    method: 'GET',
    url: '/resume/resumeitem?id=' + id + '&edit=' + edit
  })
}
// 公共获取企业信息
export const getcompanyitem = (id, edit, jid) => {
  return request({
    method: 'GET',
    url: '/company/companyitem?id=' + id + '&edit=' + edit + '&jid=' + jid
  })
}
// 公共获取岗位信息
export const getjobitem = (id, edit) => {
  return request({
    method: 'GET',
    url: '/job/jobitem?id=' + id + '&edit=' + edit
  })
}
// 获取地图
export const getmap = (keys) => {
  return request({
    method: 'GET',
    url: '/user/getmap?keys=' + keys
  })
}
// ======================
// 公共获取企业信息
export const gettc = (t) => {
  return requestpub({
    method: 'GET',
    url: '/pub/pubs?t=' + t
  })
}

// ======================
// 公共获取首页数据
export const getjsons = (t, ct) => {
  return requestpub({
    method: 'POST',
    url: '/homes/jsons?t=' + t + '&ct=' + ct
  })
}
export const gethomejsons = (url) => {
  return requestpub({
    method: 'POST',
    url: url
  })
}

export const messagesput = (data) => {
  return request({
    method: 'PUT',
    url: '/message/message',
    data: data
  })
}
// 文章列表
export const articlesget = (cid, pagesize) => {
  return request({
    method: 'GET',
    url: '/article/articleslist?pagesize=' + pagesize + '&cid=' + cid
  })
}
// 文章单条
export const articleget = (id) => {
  return request({
    method: 'GET',
    url: '/article/articleitem?id=' + id
  })
}

// 岗位数量获取
export const jobscountget = (id) => {
  return request({
    method: 'GET',
    url: '/job/jobscount'
  })
}
