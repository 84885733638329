<template>
<!-- ----------------------------------------- -->
  <div :style="stylebox">
  <div class="regcontain" v-if="showtype=='person'">
    <h2 class="regtitle">个人求职注册
      <el-button round color="#dc3023" size="small" plain style="margin-left: 32px;" @click="rtype('company')">企业招聘注册</el-button>
    </h2>
    <div class="regtitle1">一键注册，轻松找工作</div>
    <div class="regbox">
      <el-form label-width="100px" size="default" label-position="top" ref="users"  :model="users" :rules="resumerules">
        <el-form-item label="手机号" prop="usersphone">
          <el-input type="text" v-model="users.usersphone" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
        </el-form-item>
        <el-form-item label="图片验证码" prop="userspiccode">
          <el-input style="width: 88px;" type="text" v-model="users.userspiccode"  />
          <img id="captchaImg" src="" alt="captcha" style="height: 28px; margin-left: 8px;cursor: pointer;" @click="getimg()" />
        </el-form-item>
        <!---->
        <SliderVerify
      v-model:isShowSelf="sliderVConf.isShowSelf"
      :imgUrl="sliderVConf.imgUrl"
      :sText="sliderVConf.sText"
      :eText="sliderVConf.eText"
      :isBorder="sliderVConf.isBorder"
      :isParentNode="sliderVConf.isParentNode"
      :isCloseBtn="sliderVConf.isCloseBtn"
      :isReloadBtn="sliderVConf.isReloadBtn"
      :is-show-tip="isShowTip"
      :width="sliderVConf.width"
      :height="sliderVConf.height"
      @reload="emitChange('reload')"
      @show="emitChange('show')"
      @hide="emitChange('hide')"
      @close="emitChange('close')"
      @success="emitChange('success')"
      @fail="emitChange('fail')"
    ></SliderVerify>
        <!---->
        <el-form-item label="验证码" prop="userscode">
          <el-input type="text" v-model="users.userscode" style="width:88px;" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
          <el-button color="#f0f0f0" style="width:168px;margin-left: 12px;color:#333333;" @click="getcode()" :disabled="disabled">{{valiBtn}}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button color="#dc3023" style="width:100%;" :loading="loading" @click="sendreg('users')">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="regbox1">
      <a href="/login">已有帐号，马上登陆</a></div>
      <div class="regbox2">
        注册代表您已同意<a href="#"><span style="color:#dc3023;">「求职会员注册协议」</span></a><br />
      客服电话 0871-67165818  67287888 工作时间：9:30-18:30
    </div>
  </div>
  <!-- ----------------------------------------- -->
  <div class="regcontainc" v-if="showtype=='company'">
    <h2 class="regtitle">企业招聘注册
      <el-button round color="#dc3023" size="small" plain style="margin-left: 32px;" @click="rtype('person')">个人求职注册</el-button>
    </h2>
    <div class="regtitle1">一键注册，轻松找英才</div>
    <div class="regbox">
      <el-form label-width="80px" size="default" label-position="right" ref="companys"  :model="companys" :rules="companysrules">

  <el-row :gutter="20">
    <el-col :span="12">
      <el-form-item label="用户名" prop="companyusersname">
          <el-input type="text" v-model="companys.companyusersname" />
        </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="邮件" prop="companymail">
          <el-input type="email" v-model="companys.companymail" />
        </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="12">
      <el-form-item label="密码" prop="companypassword">
          <el-input type="password" v-model="companys.companypassword" />
        </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="确认密码" prop="companypasswordck">
          <el-input type="password" v-model="companys.companypasswordck" />
        </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="24">
      <el-form-item label="企业名称" prop="companyname">
          <el-input type="text" v-model="companys.companyname" />
        </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="12">
      <el-form-item label="联系人" prop="companycontacts">
          <el-input type="text" v-model="companys.companycontacts" />
        </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="手机号" prop="companyusersphone">
          <el-input type="tel" v-model="companys.companyusersphone" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" />
        </el-form-item>
    </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="12">
      <el-form-item label="验证码" prop="companycode">
          <el-input type="text" v-model="companys.companycode" />
      </el-form-item>
    </el-col>
    <el-col :span="6">
      <img :src="captchaImage" alt="验证码" height="32" />
      </el-col>
    <el-col :span="5"><el-button color="#ececec" @click="fetchCaptchaImage()" style="width:100%;margin-left: 12px;">刷新</el-button></el-col>
  </el-row>
        <el-form-item>
          <el-button color="#dc3023" style="width:100%;" @click="sendreginc('companys')">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="regbox1">
      <a href="/login">已有帐号，马上登陆</a></div>
      <div class="regbox2">
        注册代表您已同意<a href="#"><span style="color:#dc3023;">「求职会员注册协议」</span></a><br />
        客服电话 0871-67165818  67287888 工作时间：9:30-18:30
    </div>
  </div>
  </div>
</template>

<script>
import { pck, codesend, reg, increg } from '@/api/user'
import { ElMessageBox } from 'element-plus'
import token from '@/utils/token'

export default {
  name: 'regIndex',
  components: {},
  props: {},
  data () {
    const ckpassword = (companysrules, value, callback) => {
      if (this.companys.companypassword !== value) {
        callback(new Error('确认密码不正确'))
      } else {
        callback()
      }
    }
    return {
      sliderVConf: {
        isShowSelf: false,
        imgUrl: '/images/SliderVerify-1.jpg',
        sText: '已经通过验证',
        eText: '验证未通过',
        isBorder: true,
        isParentNode: true,
        isCloseBtn: false,
        isReloadBtn: false,
        width: 300,
        height: 100
      },
      isShowTip: true,
      isVerified: false, // 用于记录滑块是否通过验证
      captchaImage: '',
      valiBtn: '获取验证码',
      disabled: false,
      loading: false,
      showtype: 'person',
      users: {},
      companys: {},
      resumerules: {
        usersphone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的11位手机号码', trigger: 'blur' }
        ],
        userscode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        userspiccode: [
          { required: true, message: '请输入图片验证码', trigger: 'blur' }
        ]
      },
      companysrules: {
        companyusersname: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        companymail: [
          { type: 'string', required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        companypassword: [
          { type: 'string', required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        companypasswordck: [
          { type: 'string', required: true, message: '确认密码不能为空', trigger: 'blur' },
          { required: true, validator: ckpassword, trigger: 'blur' }
        ],
        companyname: [
          { type: 'string', required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        companycontacts: [
          { type: 'string', required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        companyusersphone: [
          { type: 'string', required: true, message: '手机号码不能为空', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的11位手机号码', trigger: 'blur' }
        ],
        companycode: [
          { type: 'string', required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      stylebox: 'width:100%;height: 800px;background-color: #f0f0f0;text-align: center; display:flex;'
    }
  },
  computed: {},
  watch: {},
  created () {
    const images = [
      '/images/SliderVerify-1.jpg',
      '/images/SliderVerify-2.jpg',
      '/images/SliderVerify-3.jpg',
      '/images/SliderVerify-4.jpg',
      '/images/SliderVerify-5.jpg'
    ]
    // 随机选择一张图片
    const randomIndex = Math.floor(Math.random() * images.length)
    this.sliderVConf.imgUrl = images[randomIndex]
    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    if (this.$route.query.rt !== undefined) {
      this.showtype = this.$route.query.rt
    }
    const listheight = document.documentElement.clientHeight - 60
    this.stylebox = 'width:100%;height: ' + listheight + 'px;background-color: #f0f0f0;text-align: center; display:flex;'
    this.fetchCaptchaImage()
    this.getimg()
  },
  mounted () {
  },
  methods: {
    rtype (v) {
      this.showtype = v
    },
    getcode () {
      if (this.users.usersphone !== undefined) {
        // alert(JSON.stringify(this.users))
        if (this.users.usersphone.length === 11) {
          this.sliderVConf.isShowSelf = true
        } else {
          ElMessageBox.alert('手机号码格式不正确！', '', {
            confirmButtonText: 'OK',
            icon: 'WarningFilled'
          })
        }
      } else {
        ElMessageBox.alert('手机号码格式不正确！', '', {
          confirmButtonText: 'OK',
          icon: 'WarningFilled'
        })
      }
    },
    tackBtn () {
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.valiBtn = '获取验证码'
          this.disabled = false
        } else {
          this.disabled = true
          this.valiBtn = time + '秒后重试'
          time--
        }
      }, 1000)
    },
    sendreg (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          reg(this.users).then(res => {
            if (res.data.status === 200) {
              // console.log('--------->token------' + res.data.resultbody.Token)
              token.settoken('utoken', res.data.resultbody.Token)
              this.$message({
                message: '登陆成功！',
                type: 'success'
              })
              this.loading = false
              this.$router.push('/resume')
            } else {
              this.loading = false
              ElMessageBox.alert(res.data.message, '', {
                confirmButtonText: 'OK',
                icon: 'WarningFilled'
              })
            }
          }).catch(res => {
            this.$message({
              message: '出错了！^-^' + JSON.stringify(res),
              type: 'error'
            })
          })
        }
      })
    },
    sendreginc (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          increg(this.companys).then(res => {
            if (res.data.status === 200) {
              token.settoken('utoken', res.data.resultbody.Token)
              this.$message({
                message: '登陆成功！',
                type: 'success'
              })
              this.loading = false
              this.$router.push('/companys/company')
            } else {
              this.loading = false
              ElMessageBox.alert(res.data.message, '', {
                confirmButtonText: 'OK',
                icon: 'WarningFilled'
              })
            }
          }).catch(res => {
            this.$message({
              message: '出错了！^-^' + JSON.stringify(res),
              type: 'error'
            })
          })
        }
      })
    },
    fetchCaptchaImage () {
      // 请求验证码图片数据
      fetch('https://24api.kmzp.com/user/getcodec')
        .then(response => response.json())
        .then(data => {
          // 将图片数据更新到data中，触发页面重新渲染
          this.captchaImage = data.b64s
        })
        .catch(error => console.error('Error fetching captcha image:', error))
    },
    getimg () {
      fetch('https://24api.kmzp.com/user/captcha?tp=pc')
        .then(response => response.json())
        .then(data => {
          if (data.captchaImage) {
            this.users.userspiccodeid = data.captchaId
            document.getElementById('captchaImg').src = data.captchaImage
          } else {
            console.error('Captcha image not found in the response.')
          }
        })
        .catch(error => console.error('Error:', error))
    },
    emitChange (vv) {
      if (vv === 'success') {
        pck(this.users.usersphone).then({})
        this.sliderVConf.isShowSelf = false
        const datainfo = 'www.kmzp.com|' + this.users.usersphone + '|' + this.users.userspiccodeid + '|' + this.users.userspiccode
        codesend(datainfo).then(res => {
          if (res.data.status === 200) {
            this.tackBtn()
            this.$message({
              message: '验证码已发送请查收！',
              type: 'success'
            })
          } else {
            this.loading = false
            ElMessageBox.alert(res.data.message, '', {
              confirmButtonText: 'OK',
              icon: 'WarningFilled'
            })
          }
        }).catch(res => {
          this.$message({
            message: '出错了！^-^' + JSON.stringify(res),
            type: 'error'
          })
        })
      } else {
        this.valiBtn = '拖动滑块完成验证'
        this.disabled = true
      }
    }
  }
}
</script>
<style scoped lang="less">
.hender_a{
  height:60px;
  display: flex;
  justify-content: center;
  align-items: top;
  width:100%;
  background-color: #ffffff;
  margin-top: 0px;
.henderbox{border-radius: 12px;background-color: #ffffff;height:46px;line-height: 46px;margin-top: 8px;width:1288px;max-width: 1288px; text-align: left;align-items: left;}
}

.regcontain{
  background-color: #ffffff;
  width: 388px;
  border-radius: 28px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}
.regcontainc{
  background-color: #ffffff;
  width: 718px;
  border-radius: 28px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}
.regtitle{text-align: center; font-size: 25px;}
.regtitle1{text-align: center;line-height: 42px;}
.regbox{
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.regbox1{text-align: center; padding-bottom: 20px;
a{color: #333333;}
}
.regbox2{text-align: center;font-size: 12px;line-height: 22px; color: #666666;
a{text-decoration:none;}
}

</style>
